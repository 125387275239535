import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { teamStyles } from "./teamStyles";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import enriqueImage from "../../images/enrique.png";
import marianellaImage from "../../images/marianella.png";
import amandaGuerraImg from "../../images/team/Amanda-guerra.png";
import anavelSuarezImg from "../../images/team/Anavel-Suarez.png";
import andreinaMoreyImg from "../../images/team/Andreina-morey.png";
import angelinaRodriguezImg from "../../images/team/Angelina-Rodriguez.png";
import barbaraCordovaImg from "../../images/team/Barbara-Cordova.png";
import barbaraGonzalezImg from "../../images/team/Barbara-gonzalez.png";
import cristobalAladejoImg from "../../images/team/Cristobal.png";
import enriqueCheangMontillaImg from "../../images/team/Enrique-Cheang-Montilla.png";
import franyuliFrancoImg from "../../images/team/Franyuli-Franco.png";
import ingrisSanchezImg from "../../images/team/Ingris-Sanchez.png";
import irinaTerraImg from "../../images/team/irina-terra.png";
import juanAraujoImg from "../../images/team/Juan-Araujo.png";
import lenysSerranoImg from "../../images/team/Lenys.png";
import leomarisSantaellaImg from "../../images/team/Leomaris-Santaella.png";
import mariaOriguenImg from "../../images/team/Maria-Gabriela-Origuen.png";
import marianaDouaihyImg from "../../images/team/Mariana-Douaihy.png";
import marisolOrnelaImg from "../../images/team/Marisol-ornela-rodriguez.png";
import miguelDominguezImg from "../../images/team/Miguel-dominguez.png";
import nathalyPrascaImg from "../../images/team/Nathaly-Prasca.png";
import onasisSolarImg from "../../images/team/Onasis-Solar.png";
import reinaldoVelizImg from "../../images/team/Reinaldo-veliz.png";
import rocioEscalanteImg from "../../images/team/Rocio-escalante.png";
import rosaMoraImg from "../../images/team/Rosa-Mora.png";
import valetinaOrozcoImg from "../../images/team/Valentina-Orozco.png";
import { BioDialog } from "../BioDialog/BioDialog";

const useStyles = makeStyles((theme) => teamStyles(theme));

export const TeamSection = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [showTeam, setShowTeam] = React.useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);

  const [selectedEmployee, setSelectedEmployee] = React.useState({});

  const handleClickOpen = (name, title, image, bioKey, email) => {
    setSelectedEmployee({
      name: name,
      title: title,
      image: image,
      bioKey: bioKey,
      email: email,
    });
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
    // setSelectedEmployee({});
  };

  const handleShowTeamChange = () => {
    setShowTeam((prev) => !prev);
  };

  return (
    <>
      <Container id="team" className={classes.container}>
        <Typography
          className={classes.title}
          variant="h4"
          color="secondary"
          align="center"
        >
          {t("teamTitle")}
        </Typography>
        <Grid container justifyContent="center">
          <Box className={classes.underline} />
        </Grid>
        <Typography variant="body1" color="textSecondary" align="center">
          {t("teamDescription")}
        </Typography>

        <Grid
          container
          className={classes.grid}
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid
            item
            container
            md={4}
            sm={6}
            xs={12}
            className={classes.item}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Box
              onClick={() =>
                handleClickOpen(
                  "Enrique Cheang",
                  t("foundersTitle"),
                  enriqueImage,
                  "enriqueCheangBio",
                  "echeang@ecv.com.ve"
                )
              }
              sx={{ cursor: "pointer" }}
            >
              <Box
                sx={{
                  marginBottom: "20px",
                }}
                display="flex"
              >
                <img
                  className={classes.image}
                  alt="Enrique Cheang"
                  src={enriqueImage}
                />
              </Box>
              <Typography variant="h6" align="center" color="primary">
                Enrique Cheang
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
              >
                {t("foundersTitle")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            container
            md={4}
            sm={6}
            xs={12}
            className={classes.item}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Box
              onClick={() =>
                handleClickOpen(
                  "Marianella Montilla",
                  t("foundersTitle"),
                  marianellaImage,
                  "marianellaMontillaBio",
                  "mmontilla@ecv.com.ve"
                )
              }
              sx={{ cursor: "pointer" }}
            >
              <Box
                sx={{
                  marginBottom: "20px",
                }}
                display="flex"
              >
                <img
                  className={classes.image}
                  alt="ECV Nosotros"
                  src={marianellaImage}
                />
              </Box>
              <Typography variant="h6" color="primary" align="center">
                Marianella Montilla
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
              >
                {t("foundersTitle")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              size="large"
              onClick={handleShowTeamChange}
              endIcon={showTeam ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {t("meetTheTeam")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={showTeam}>
              <Box sx={{ paddingTop: 40 }}>
                <Grid
                  container
                  spacing={8}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary" align="center">
                      {t("teamIntelectualProperty")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Amanda Guerra",
                          `IP Senior Executive - ${t("piSpecialistTitle")}`,
                          amandaGuerraImg,
                          "amandaGuerraBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Amanda Guerra"
                          src={amandaGuerraImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Amanda Guerra
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {`IP Senior Executive - ${t("piSpecialistTitle")}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Anavel Suárez",
                          t("piAndCLSpecialistTitle"),
                          anavelSuarezImg,
                          "anavelSuarezBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Anavel Suárez"
                          src={anavelSuarezImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Anavel Suárez
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("piAndCLSpecialistTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Bárbara González",
                          t("piSpecialistTitle"),
                          barbaraGonzalezImg,
                          "barbaraGonzalezBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Bárbara González"
                          src={barbaraGonzalezImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Bárbara González
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("piSpecialistTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Onasis Solar",
                          t("piSpecialistTitle"),
                          onasisSolarImg,
                          "onasisBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Onasis Solar"
                          src={onasisSolarImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Onasis Solar
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("piSpecialistTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Leomaris Santaella",
                          t("clSpecialistTitle"),
                          leomarisSantaellaImg,
                          "leomarisBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Leomaris Santaella"
                          src={leomarisSantaellaImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Leomaris Santaella
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("clSpecialistTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "María Gabriela Origuen",
                          t("paralegalTitle"),
                          mariaOriguenImg,
                          "mariaOriguenBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Maria Gabriela Origuen"
                          src={mariaOriguenImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        María Gabriela Origuen
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("paralegalTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Nathaly Prasca",
                          t("paralegalTitle"),
                          nathalyPrascaImg,
                          "nathalyBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Nathaly Prasca"
                          src={nathalyPrascaImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Nathaly Prasca
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("paralegalTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Franyuli Franco",
                          t("paralegalTitle"),
                          franyuliFrancoImg,
                          "franyuliBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Franyuli Franco"
                          src={franyuliFrancoImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Franyuli Franco
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("paralegalTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Enrique Cheang Montilla",
                          t("paralegalTitle"),
                          enriqueCheangMontillaImg,
                          "enriqueCheangMontillaBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Enrique Cheang Montilla"
                          src={enriqueCheangMontillaImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Enrique Cheang Montilla
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("paralegalTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Cristóbal Aladejo",
                          t("branchManagerTitle"),
                          cristobalAladejoImg,
                          "cristobalBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Cristóbal Aladejo"
                          src={cristobalAladejoImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Cristóbal Aladejo
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("branchManagerTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Lenys Serrano",
                          t("customerServiceTitle"),
                          lenysSerranoImg,
                          "lenysBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Lenys Serrano"
                          src={lenysSerranoImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Lenys Serrano
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("customerServiceTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Andreina Morey",
                          t("administrationTitle"),
                          andreinaMoreyImg,
                          "andreinaMoreyBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Andreina Morey"
                          src={andreinaMoreyImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Andreina Morey
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("administrationTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Bárbara Córdova",
                          t("collectionsManagerTitle"),
                          barbaraCordovaImg,
                          "barbaraCordovaBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Bárbara Córdova"
                          src={barbaraCordovaImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Bárbara Córdova
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("collectionsManagerTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Rosa Mora",
                          t("communicationsTitle"),
                          rosaMoraImg,
                          "rosaMoraBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Rosa Mora"
                          src={rosaMoraImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Rosa Mora
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("communicationsTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      sx={{
                        marginBottom: "20px",
                      }}
                      display="flex"
                    >
                      <img
                        className={classes.imageTeam}
                        alt="Reinaldo Veliz Luna"
                        src={reinaldoVelizImg}
                      />
                    </Box>
                    <Typography variant="h6" color="primary" align="center">
                      Reinaldo Veliz Luna
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      align="center"
                    >
                      {t("itDepartmentTitle")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      sx={{
                        marginBottom: "20px",
                      }}
                      display="flex"
                    >
                      <img
                        className={classes.imageTeam}
                        alt="Ingris Sanchez"
                        src={ingrisSanchezImg}
                      />
                    </Box>
                    <Typography variant="h6" color="primary" align="center">
                      Ingris Sanchez
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      align="center"
                    >
                      {t("recordsTitle")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      sx={{
                        marginBottom: "20px",
                      }}
                      display="flex"
                    >
                      <img
                        className={classes.imageTeam}
                        alt="Rocio Escalante"
                        src={rocioEscalanteImg}
                      />
                    </Box>
                    <Typography variant="h6" color="primary" align="center">
                      Rocio Escalante
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      align="center"
                    >
                      {t("generalServicesTitle")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      sx={{
                        marginBottom: "20px",
                      }}
                      display="flex"
                    >
                      <img
                        className={classes.imageTeam}
                        alt="Juan Araujo"
                        src={juanAraujoImg}
                      />
                    </Box>
                    <Typography variant="h6" color="primary" align="center">
                      Juan Araujo
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      align="center"
                    >
                      {t("generalServicesTitle")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary" align="center">
                      {t("teamBusiness")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Miguel Dominguez Franchi",
                          t("corporatePartnerTitle"),
                          miguelDominguezImg,
                          "miguelBio",
                          "empresas@ecv.com.ve"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Miguel Dominguez Franchi"
                          src={miguelDominguezImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Miguel Dominguez Franchi
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("corporatePartnerTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Marisol Ornela Rodriguez",
                          t("bankingPartnerTitle"),
                          marisolOrnelaImg,
                          "marisolBio",
                          "corporativo@ecv.com.ve"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Marisol Ornela Rodriquez"
                          src={marisolOrnelaImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Marisol Ornela Rodriguez
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("bankingPartnerTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Irina Terra",
                          t("associateRLTitle"),
                          irinaTerraImg,
                          "irinaBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Irina Terra"
                          src={irinaTerraImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Irina Terra
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("associateRLTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Valentina Orozco Duarte",
                          t("lawyerAssociateTitle"),
                          valetinaOrozcoImg,
                          "valentinaOrozcoBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Valentina Orozco Duarte"
                          src={valetinaOrozcoImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Valentina Orozco Duarte
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("lawyerAssociateTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Mariana Douaihy",
                          t("paralegalTitle"),
                          marianaDouaihyImg,
                          "marianaBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Mariana Douaihy"
                          src={marianaDouaihyImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Mariana Douaihy
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("paralegalTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        handleClickOpen(
                          "Angelina Rodríguez Petrillo",
                          t("paralegalTitle"),
                          angelinaRodriguezImg,
                          "angelinaRodriguezBio"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                        display="flex"
                      >
                        <img
                          className={classes.imageTeam}
                          alt="Angelina Rodríguez Petrillo"
                          src={angelinaRodriguezImg}
                        />
                      </Box>
                      <Typography variant="h6" color="primary" align="center">
                        Angelina Rodríguez Petrillo
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {t("paralegalTitle")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </Grid>
        </Grid>
      </Container>
      <BioDialog
        isOpen={modalOpen}
        handleClose={handleClose}
        image={selectedEmployee.image}
        classes={classes}
        name={selectedEmployee.name}
        workTitle={selectedEmployee.title}
        bioKey={selectedEmployee.bioKey}
        email={selectedEmployee.email}
      />
    </>
  );
};
